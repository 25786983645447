.cover-content{
    /* position: fixed; */
    position: relative;
    /* margin-top: clamp(80px, 8vh, 8vh); */

    padding: 3vh 3vh;
    /* top: 50%; */
    margin-bottom: clamp(200px, 20vh, 20vh);

    width: 90vw;
    min-height: 10vh;
    
    /* background-color: white; */
    background-color: transparent;
    
    border-radius: 1rem;
}

.content-grid{
    position: relative;

    width: 100%;
    min-height: 5vh;

    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 1vw;

    /* border: 2px solid red; */
}

.content-item{
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 30vw;

    object-fit: cover;
    vertical-align: middle;
    
    outline: 1px solid var(--templateBackground);

    cursor: pointer;

    transition: 0.3s ease-in-out;

    z-index: 2; 
}

.disabled{
    cursor: default;
}

.content-item-img{
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 30vw;

    object-fit: cover;
    vertical-align: middle;
    
    outline: 1px solid var(--templateBackground);

    cursor: pointer;

    transition: 0.3s ease-in-out;

    z-index: 2; 
}

.content-item-text{
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);

    transition: 0.3s ease-in-out;

    text-align: center;
    color: white;
    font-weight: 700;

    opacity: 0;

    z-index: 3;
}

.content-item:hover{
    outline: 7px solid var(--templateBackground);
    /* outline: 7px solid #eddcd2; */
    outline-offset: -5px;
}

.content-item:hover .content-item-img{
    
    filter: brightness(50%);
}

.content-item:hover .content-item-text{
    opacity: 1;
    filter: brightness(100%);
}

/*///////////////////////////////////////////////*/
/*/////////////////FinalTemp/////////////////////*/
/*///////////////////////////////////////////////*/

.cover-header{
    position: relative;
    top: 8vh;
    left: 0;

    /* margin-top: clamp(80px, 8vh, 8vh); */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    min-height: 100vh;
}

.cover-image{
    /* position: fixed; */
    /* margin-top: clamp(80px, 8vh, 8vh); */
    width: 100%;
    height: 80vh;

    object-fit: cover;
}

.scroll {
    width: 60px;
    height: 60px;
    
    /* border: 5px solid black; */
    /* background-color: black; */
    border-radius: 50%;

    position: absolute;
    top:92vh;
    left:50%;
    transform: translate(-50%,0);

    z-index: 100;
    
    cursor: pointer;
    animation: down 1.5s infinite;
    -webkit-animation: down 1.5s infinite;
}

.scroll:before {
      content: '';
      position: absolute;
      top: 10px;
      left: 13px;
      width: 18px;
      height: 18px;
      border-left: 5px solid white;
      border-bottom: 5px solid white;
      border-top: 0;
      border-right:0 ;
      border-radius: 2px;
      /* box-shadow: 0px -5px 30px black; */
      transform: rotate(-45deg);
}

@keyframes down {
    0% {
      transform: translate(-50%,0);
    }
    20% {
      transform: translate(-50%,15px);
    }
    40% {
      transform: translate(-50%,0);
    }
  }

.final-template-title{
    width: 100%;
    min-height: 20vh;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    padding:0 5vw ;

    font-size: 2rem;
    /* color: white; */
    color: white;
    text-align: center;

    /* border: 2px solid red; */
}

.template-title{
    margin-top:clamp(80px, 8vh, 8vh); 
}


.template-subscript{
    margin-top: 30px;
    width:50%; 

    font-family: 'Quattrocento', serif;
    font-size:1rem;
    color: rgb(221, 221, 221);
    /* color: black; */
}

.final-template-video{
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;

    /* width: clamp(50%); */

    margin-bottom: 50px;
    object-fit: contain;
}

.final-template-writeup{
    position: relative;
    
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;

    width: 50%;
    height: auto;
    
    padding: 1rem 0;
    margin-bottom: 10vh;

    /* font-family: 'Quicksand', sans-serif; */
    font-family: 'Quattrocento', serif;
    font-size: 1.2rem;
    /* word-spacing: 10px; */
    color: rgb(206, 206, 206);
    text-align: center;

    transition: 0.3s ease-in-out;
    /* border: 2px solid red; */
}

.final-template-writeup:hover{
    color: rgb(255, 255, 255);
}

.final-template-writeup::after{
    content : "";
    position : absolute;
    width : 100%;
    height : 1px;
    background-color: gray;
    bottom: 0;
    left: 0;
    transform : scaleX(0.3) ;
    transform-origin: bottom center;
    transition : transform 0.4s linear;
}

.final-template-writeup:hover::after{
    /* width: 40%; */
    transform: scaleX(0.25) ;
}

.error404{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.final-template-title>h1{
    width:fit-content;
    justify-self: center;
    text-decoration: none; 
    position: relative;
    /* border: 2px solid red; */
}

.final-template-title>h1::after{
    content : "";
    position : absolute;
    width : 100%;
    height : 3px;
    background-color: white;
    bottom: 0;
    left: 0;
    transform : scaleX(0.5) ;
    transform-origin: bottom center;
    transition : transform 0.4s linear;
}

.final-template-title>h1:hover::after{
    transform: scaleX(0.25) ;
}

.copyright-footer{
    position: absolute;
    bottom: 0;
    font-family: 'Albert Sans', sans-serif;
    text-align: center;
    margin-bottom: 1rem;
    color: #696969;
}

/*///////////////////////////////////////////////*/
/*///////////////Responsive Design///////////////*/
/*///////////////////////////////////////////////*/
@media only screen and (max-width: 900px ){
    .final-template-video{
        width: 95%;
    }
}
@media only screen and (max-width: 768px ){
    .final-template-title{
        font-size: 1rem;
    }

    .template-title{
        font-size: 1rem;
    }

    .final-template-video{
        width: 90%;
        height: auto;
    }

    .cover-image{
        height: 70vh;
    }

    .final-template-writeup{
        font-size: 1rem;
    }

    .template-subscript{
        width: 100%;
        font-size: 0.8rem;
    }

    .content-grid{
        grid-template-columns: 50% 50% ;
    }

    .copyright-footer{
        font-size: 0.8rem;
        margin-bottom: 4rem;
    }

    .content-item-img{
        filter: brightness(70%);
    }

    .content-item-text{
        opacity: 1;
        font-size: 0.8rem;
    }
  }