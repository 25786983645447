.popup-container{
    position: absolute;
    bottom: 0;
    left: 0;

    padding: 1rem 0;

    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:flex-start;

    /* width: clamp(250px,15vw,15vw); */
    width: auto;
    min-height: 5vh;

    /* z-index: 2; */

    /* background-image: linear-gradient(5deg, rgba(0, 0, 0,0.9), rgba(255, 255, 255, 0)); */

    /* border: 2px solid red; */
}

.popup-left-container{
    /* position: relative; */
    width: clamp(150px,15vw,15vw);
}

.popup-item{
    margin-left: 1rem;
    padding: 0.4rem 1rem;

    font-size: 1rem;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    color: white;
    
    /* border: 2px solid green; */
}

.popup-item:hover{
    color: rgb(88, 88, 88);
}

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 768px ){
    .popup-item{
        margin-left: 0;
        font-size: 0.8rem;        
    }
}