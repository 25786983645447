.client-container{
    /* position: absolute; */
    position: fixed;
    top:0;
    left:50%;
    transform: translate(-50%,25px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 70%;
    height: 100vh;

    background-color: transparent;
    /* background-color: black; */

    /* border: 2px solid red; */

    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
}

.blur-client-box{
    filter: blur(12px) brightness(50%) ;
    -webkit-filter: blur(12px) brightness(50%);
}

.client-grid{
    /* position: absolute;
    top:0; */
    position: relative;

    width: 55vw;
    height: 70vh;

    display: grid;
    grid-template-columns: auto auto auto auto auto;

    /* display: flex;
    flex-direction: column; */

    overflow-y: scroll;
    overflow-x: hidden;

    /* z-index: 5; */

    /* border: 2px solid blue; */
}

/* .client-logo{
    width: 10vw;
    height: 10vw;

    padding: none;
    margin: none ;

    object-fit: contain;

} */

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 768px ){
    .client-container{
        height: 90vh;
        top: 0;
        left: 60%;
        width: 60vw;
    }

    .client-grid{
        height: 70vh;
        grid-template-columns: auto auto auto auto;
    }
  }