.imageSlider-container{
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    height: 100vh;

    z-index: 1;
}

.slider{
    position: fixed;

    width: 75vw;
    height: 80vh;

    object-fit: contain;

    /* border: 2px solid blue; */
}

.hidden{
    display: none;
}

.slider-icon{
    position: fixed;
    top: 50%;

    color: white;
    font-size: 1.5rem;

    cursor: pointer;
}

.left-icon{
    left: 25px;
}

.right-icon{
    right: 25px;
}

.close{
    top:clamp(85px, 8vh, 8vh);
    right: 20px;
}

@media only screen and (max-width: 768px ){
    .slider{
        width: 70vw;
        height: 80vh;
    }
}