.transparent-container{
    position: fixed;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    /* padding-top: 50vh; */
    
    width: 100%;
    /* height: clamp(80px, 5vh, 5vh); */
    height: 80px;
    
    background-color: transparent;
    /* border: 2px solid red; */

    z-index: 2;

    /* overflow: hidden; */
    overflow-y: visible;
}

.footer-container{
    position: absolute;
    bottom:0;

    padding: 0 1rem;

    display: flex;
    /* flex-direction: row; */
    justify-content:space-between;
    align-items:flex-end;

    width: 100%;
    height: clamp(80px, 8vh, 8vh);
  
    background-image: linear-gradient(0deg, rgba(0, 0, 0,0.3), rgba(255, 255, 255, 0));
    
    z-index: 1;
    /* border: 2px solid red; */
}

.footer-dark{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0));
}


.btn-footer{
margin: 1rem;

width: auto;
height: auto;

font-size: 1.5rem;
font-weight: bolder;
color: white;

transition: 0.3s ease-in-out;
cursor: pointer;

/* border: 2px solid red; */

-webkit-user-select: none; /* Safari */
-ms-user-select: none; /* IE 10 and IE 11 */
user-select: none; /* Standard syntax */
}

.dark-explore{
    /* border: 1px solid var(--templateBackground); */
    color: var(--dark-btn-color);
}

.social-btn{
    margin:0 1rem; 
    color:white;
    transition: 0.3s ease-in-out;
}


.explore-btn{
    /* text-shadow: 0 0 50px #ffffff; */
    border: 1px solid var(--templateBackground);
    /* animation: neonGlow 2s infinite alternate  cubic-bezier(0.455, 0.030, 0.515, 0.955); */
}

@keyframes neonGlow {
  0% {
    text-shadow: 0 0 10px rgba(255,255,255,.8),
                 0 0 20px rgba(255,255,255,.8),
                 0 0 22px  rgba(255,255,255,.8),
                 0 0 40px  rgba(220, 250, 250, 0.8),
                 
                 0 0 200px rgba(220, 250, 250,.5);
  }
  100% {
    text-shadow: 0 0 2px rgba(255,255,255,.8),
                 0 0 8px rgba(255,255,255,.8),
                 0 0 10px rgba(255,255,255,.8),
                 
                 0 0 80px rgba(220, 250, 250,.5);
  }
}


.disabled{
    color:rgb(88, 88, 88);
    cursor: default;
}



/*//////////Responsive Design///////////*/
@media only screen and (max-width: 768px ){
    .btn-footer{
        margin-left: 0;
        font-size: 1rem;        
    }
}