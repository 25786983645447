.award-content{
    position: relative;
    width: 50%;
    min-height: 40vh;

    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5.9px);
    -webkit-backdrop-filter: blur(5.9px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 50px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5vh 0;
    grid-template-columns: 30% 20%;
}

.award-template-title{
    width: 100%;
    min-height: 20vh;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    padding:0 5vw ;

    font-size: 2rem;
    text-align: center;
    color: white;
}

.award-template-title>h1{
    width:fit-content;
    justify-self: center;
    text-decoration: none; 
    position: relative;
    text-shadow: 0px -5px 30px black;
}

.award-template-title>h1::after{
    content : "";
    position : absolute;
    width : 100%;
    height : 3px;
    background-color:  white;
    bottom: 0;
    left: 0;
    transform : scaleX(0.5) ;
    transform-origin: bottom center;
    transition : transform 0.4s linear;
}

.award-template-title>h1:hover::after{
    transform: scaleX(0.25) ;
}

.award-slider-icon{
    position: absolute;
    top:50%;

    /* color: #1d1d1d; */
    color: white;

    font-size: 1.5rem;

    cursor: pointer;
    z-index: 1;
}

.left-icon-award{
    left:10px;
}

.right-icon-award{
    right:10px;
}

.hidden{
    display: none;
}

.award-grid{
    width: 80%;
    height: 40vh;
    justify-self: center;

    display: flex;
    /* display: grid; */
    /* grid-template-columns: 35% 50%; */
    /* gap:2rem; */
    /* border: 2px solid red; */
}

.award-img{
    position: relative;
    /* justify-self: center; */
    align-self: center;

    width: 35%;
    height: 100%;

    object-fit:contain;
    /* border: 2px solid red; */
    border-radius:30px ;

    margin-right: 1rem;

}

.award-name-box{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
}

.award-name{
    /* color: #000000; */
    color: white;
    word-wrap: normal;
    font-size: 1rem;
    /* border: 2px solid red; */
}

@media only screen and (max-width: 900px ){
    .award-content{
        width: 60%;
    }
    .award-img{
        width: 50%;
    }
    /* .award-grid{
        grid-template-columns: 50% 50%;
    } */
}

@media only screen and (max-width: 786px ){
    .award-content{
        width: 85%;
    }
    .award-name{
        padding-right: 1rem;
    }
    .left-icon{
        left:5px;
    }
    
    .right-icon{
        right:5px;
    }
}