.navbar-container{
    position: fixed;
    top:0;

    display: grid;
    grid-template-columns: 15% 70% 15%;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    height: clamp(80px, 8vh, 8vh);

    transform: translate(0,0);
    background-color: var(--templateBackground);
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0)); */
    /* background-image: none; */
    z-index: 2;

    transition: 0.1s ease-in-out;

    /* border: 2px solid red; */
}

.navbar-dark-container{
    background-color: transparent;
    background-image: linear-gradient( rgba(0, 0, 0, 0.7),
                                        rgba(0, 0, 0, 0) 
                                        );
    /* background-image: linear-gradient(180deg, rgba(0, 0, 0,0.6), rgba(255, 255, 255, 0)); */
    
    /* transition: 0.3s ease-in-out; */

    /* border: 1px solid red; */
}

.hidden{
    transform: translate(-100vw,0);
}

.fake-backdrop{
    position: absolute;
    top:0;

    width: 100%;
    height: clamp(80px, 8vh, 8vh);

    /* background-color: rgb(80, 15, 15); */

    filter: brightness(10%);
    -webkit-filter: brightness(10%);

    transition: 0.3s ease-in-out;

    /* border: 2px solid red; */
}

.red-back{
    position: absolute;
    top:0;

    width: 100%;
    height: clamp(80px, 8vh, 8vh);

    background: red;

    /* border: 2px solid red; */

    transition: 0.3s ease-in-out;
}

.nav-item{
    display: flex;
    justify-content: space-around;
    align-items: center;

    width: 100%;
    height: 100%;

    font-size: 0.5rem;

    /* border: 2px solid green; */
}

/* .nav-item:first-child{
    justify-content: space-around;
} */

.nav-item:first-child{
    justify-content: flex-start;
    padding-left: 1rem;
}

.popup-mobile-container{
    position: fixed;
    top: 10vh;
    left: 0.5rem;

    /* border: 2px solid red; */
}

.icon-mobile{
    width:1.5rem;
    height:auto;

    color: white;

    cursor: pointer;
}

.icon-disabled{
    width:1.5rem;
    height:auto;

    color:  rgb(88, 88, 88);
    cursor: default;
}

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 1400px ){
    .navbar-container{
        grid-template-columns: 25% 50% 25%;
    }
}

@media only screen and (max-width: 768px ){
    .navbar-container{
        grid-template-columns: 15% 70% 15%;
        
    }
}