.client-logo{
    max-width:250px;
    width: 10vw;
    max-height: 150px;
    height: auto;
    /* aspect-ratio: 1/2; */

    padding: none;
    margin-bottom: 2rem ;

    object-fit: contain;

    background-color: rgba(255, 255, 255, 0.356);
    border-radius: 20px;

    /* border: 2px solid blue; */
    /* box-shadow: 0px 0px 10px white inset; */
}

/* .client-logo:nth-child(even){
    background-color: #acacac63;
} */

.white{
    background-color: rgba(255, 255, 255);
    /* filter:invert(1) */
    /* filter: drop-shadow(0 0 2rem white); */
}

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 768px ){
    .client-logo{
        border-radius: 5px;
    }
  }