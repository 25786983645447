/* @import url('https://fonts.googleapis.com/css2?family=Albert+Sans:wght@300&display=swap'); */

.content-box{
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,100px);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 2rem 4rem;

    width: clamp(300px,45vw,45vw);
    min-height: 50vh;
    max-height: 80vh;

    
    /* font-size: 0.8rem; */
    color: white;
    text-align: center;

    overflow: auto;

    z-index: 1  ;
    /* border: 2px solid green; */
}

.content-box>p{
    font-size: 1rem;
    /* font-family: 'Playfair Display', serif; */
    font-family: 'Albert Sans', sans-serif;
    /* border: 2px solid red; */
    /* font-weight: 300; */
    padding-bottom: 1rem;
}

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 1200px ){
    .content-box{
        width: clamp(600px,60vw,60vw);
    }
  }

  @media only screen and (max-width: 768px ){
    .content-box{
        top:0;
        left: 60%;
        width: 60vw;
        padding: 2rem 0.5rem;
    }

    .content-box>p{
        font-size: 0.5rem;
    }
  }