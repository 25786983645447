/* @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@1&display=swap'); */
.testimonial-container{
    background-color: transparent;
    /* border: 2px solid red; */
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%,25px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 80%;
    height: 100vh;

    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
}

.hidden{
    display: none;
}

.testimonial-ref-box:focus{
    /* width: 100%; */
    height: auto;
    border: none;
    outline: none;
}

.testimonial-window{
    position: relative;
    justify-content: center;

    width: 50vw;
    height: 70vh;

    overflow-y: scroll;
    overflow-x: hidden;

    /* border: 2px solid blue; */
}


.testimonial-grid{
    /* position: absolute;
    top:0; */
    position: relative;

    margin-bottom: 100px;

    justify-content: center;

    width: 99%;
    height: auto;
    /* min-height: 70vh; */

    display: grid;
    grid-template-columns: 35% 60% ;
    gap:2rem;
}

/* .testimonial-grid:last-child{
    margin-bottom: 60px;
} */
.testimonial-img-container{
    width: clamp(200px,100%,100%);
    height: auto;
    max-height:60vh ;
    aspect-ratio: 1/1.1;
    
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* border: 2px solid blue */
}

.testimonial-img{
    /* border: 2px solid blue; */
    /* align-self: center; */
    width: 100%;
    object-fit:contain;
}

.testimonial-content{
    /* border: 2px solid green; */
    position: relative;
    color: white;
}

.testimonial-content>h1{
    margin-bottom: 1.5rem;
}

.quotes{
    position: relative;
    display: flex;
    width: 95%;
    flex-direction: column;
    font-size: 60px;
    /* border: 2px solid red; */
}

.quote-content{
    display: flex;
    width: 80%;
    font-family: 'Playfair Display', serif;
    font-size: 1rem;
    font-weight: lighter;
    margin: 1rem 0 1.5rem 1.5rem; 
}

.quotes::before{
    content: open-quote;
}

.quotes::after{
    content: close-quote;
}

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 768px ){
    .testimonial-container{
        height: 90vh;
        top: 0;
        left: 60%;
        width: 60vw; 

        /* border: 2px solid red; */

    }

    .testimonial-window{
        width: 50vw;
        height: 70vh;

        /* border: 2px solid blue; */
    }

    .testimonial-grid{
        min-height: 40vh;
        grid-template-columns: auto;
        grid-template-rows: auto auto;

        margin-bottom: 50px;

        /* border: 2px solid green; */
    }

    .testimonial-grid:last-child{
        margin-bottom: none;
    }

    .testimonial-img-container{
        width:100% ;
        justify-content: flex-start;
        align-items: center;
        /* border: 2px solid red; */
    }

    .testimonial-img{
        /* border: 2px solid blue; */
        justify-self: center;
        width: 100%;
        /* align-self: center; */
        max-height:30vh ;
        height: auto;
        object-fit: contain;
    }

    .testimonial-content>h1{
        display: flex;
        font-size: 0.8rem;
        margin-bottom: 0.8rem;
        /* min-height: 1rem; */

        /* border: 2px solid red; */
    }
    .testimonial-content>h3{
        display: flex;
        font-size: 0.5rem;
        /* min-height: 1rem; */

        /* border: 2px solid red; */
    }

    .quote-content{
        width: 90%;
        font-size: 0.5rem;
        margin: 0.5rem 0 0rem 0; 
    }
  }