.form-container{
    margin-top: 2rem;
    width: 80%;
    padding: 0 5vw;
    
    /* border: 2px solid red; */
}

.error-content{
    position: fixed;
    bottom: 10vh;

    width: 20vw;
    height: auto;
    
    font-size: 1rem;
    color: white;

    background-color: transparent;
    /* border: 2px solid blue; */
}

.form-grid{
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
}

.form-group{
    padding: 0.5rem;
    
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid blue; */
}

.form-group>label{
    font-size: 0.8rem;
}

.form-control{
    margin-left:0.5rem;
    padding: 0.3rem;
    
    background-color: transparent;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid rgba(167, 167, 167, 0.5);

    font-family: 'Albert Sans', sans-serif;

    color: white;
    font-size: 0.8rem;
    font-weight: 500;
    /* border: 2px solid red; */
}

.form-control:focus{
    background-color: transparent;
    border:none;
    border-bottom: 1px solid rgb(192, 192, 192);
    outline: none;
}

.form-control::selection{
    color:white;
    background: rgb(141, 141, 141);
}

.form-group-textarea{
    grid-column: 1 / span 2;
}

.form-group-textarea>label{
    align-self: flex-start;
}

.form-control-textarea{
    width: 70%;
    text-align: justify;
    border-bottom: 1px solid rgba(167, 167, 167, 0.5);
    resize: none;
}

.submit-btn{
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    transition: 0.3s ease-in-out;
}

.submit-btn:hover{
    color:  rgb(66, 66, 66)
}

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 768px ){
    .form-container{
        width: 100%;
        /* border: 2px solid red; */
    }

    .form-grid{
        grid-template-columns: auto;
        /* grid-template-rows: auto auto auto; */
    }

    .form-group{
        grid-column: span 2;
    }

    .form-group>label{
        font-size: 0.5rem;
    }

    .form-control{
        font-size: 0.5rem;
    }

    .submit-btn{
        font-size: 0.7rem;
    }

    .error-content{
        font-size: 0.7rem;
        margin-bottom:5vh;
    }
}