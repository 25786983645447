/* @import url('https://fonts.googleapis.com/css2?family=Aboreto&display=swap'); */
:root{
  /* --templateBackground: #ebd0c5; */
  --templateBackground: #000000;
  /* --templateBackground: #171718; */
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family:'Poiret One', cursive; */
  font-family: 'Aboreto', cursive;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  /* font-family: "Fragile"; */

  font-display: block;
}

.container{
  width: 100%;
  min-height: 100vh;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: black;
  
  color: antiquewhite;

  overflow: hidden;
  /* border: 2px solid blue; */
}

.moa-box{
  height: 75vh;

  /* border: 1px solid red; */
}

.moa-box>img{
  height: inherit;
  width: auto;

  object-fit: contain;
}

.cr-doc-btn-box{
  width: 50vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  row-gap: 2vh;

  /* border: 1px solid red; */
}

.cr-doc-btn{
  width: 100%;
  height: 7vh;

  padding: 0.5rem 1rem;

  /* border: 1px solid blue; */

  background-color: #adadad;
  cursor: pointer;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: var(--templateBackground);

  transition: 0.3s ease-in-out;
}

.cr-doc-btn:hover{
  background-color: #e5e5e5;
  transition: 0.3s ease-in-out;
}

.template-container{
  min-height: 100vh ;

  background-color: var(--templateBackground);
  /* background-color: #eddcd2; */
}



.media-container{
  min-height: 100vh;
  background: url(https://ik.imagekit.io/qljqw3tvn/tr:w-1800w/Rashi/Media.jpg) fixed right no-repeat;
  background-size: cover;
}

.awards-container{
  min-height: 100vh;
  background: url(https://ik.imagekit.io/qljqw3tvn/tr:w-1800w/Rashi/Awards__copy__I7b-2FTGW.jpg) fixed center no-repeat;
  /* filter: brightness(90%); */
  background-size: cover;
}

.blur-template{
  filter: blur(15px) brightness(45%);
  -webkit-filter: blur(15px) brightness(45%);
}

.overlay-container{
  position: absolute;
  top:0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: white;
  /* background-image: url('/public/images/home.jpg'); */
  z-index: 2;
}

.overlay-container>h1{
  color: black;
}


/*///////////////////////////////////////////////*/
/*/////////////////R Icon////////////////////////*/
/*///////////////////////////////////////////////*/
.r{
  width: clamp(30px,2.5vw,2.5vw);
  height: auto;
  
  position: absolute;
  top: 0;
  left: 0;

  cursor: pointer;
  transform: translate(-20%, -25%);

  filter: drop-shadow(0mm 0mm 5px rgb(255, 197, 197));

  z-index: 1;
}

.r-dot{
  position: absolute;
  left: 0;
  top: 0;
  
  transform: translateX(-50%) translateY(-50%);
  width: clamp(10px,1vw,1vw);
  height: clamp(10px,1vw,1vw);

  

  z-index: 1;
  cursor: pointer;
}

.r-dot:focus, .r-dot-disable:focus{
  border: none;
  outline:none;
}

.r-disable{
  cursor: default;
}

.r-dot-disable{
  cursor: default;
}

.r-dot::before {
  content: '';
  position: relative;
  display: block;
  width: 500%;
  height: 500%;
  box-sizing: border-box;
  margin-left: -200%;
  margin-top: -200%;
  border-radius: 45px;
  /* background-color: #01a4e9; */
  background-color: #ff0e0e;
  /* background-color: #f1faee; */
  animation: pulse-ring 1s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.r-dot::after {
  content: '';
  position: absolute;
  left: 0; 
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  /* background-color: white; */
  background-color: transparent;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0,0,0,.3);
  animation: pulse-dot 1.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
}

@keyframes pulse-ring {
0% {
  transform: scale(.33);
}
80%, 100% {
  opacity: 0;
}
}

@keyframes pulse-dot {
0% {
  transform: scale(.8);
}
50% {
  transform: scale(1);
}
100% {
  transform: scale(.8);
}
}

/*///////////////////////////////////////////////*/
/*/////////////////Button////////////////////////*/
/*///////////////////////////////////////////////*/

.btn{
  width: auto;
  height: auto;

  font-size: 1rem;
  font-weight:bolder;
  color: white;
  /* color: var(--dark-btn-color); */

  margin:1rem;

  transition: 0.3s ease-in-out;
  cursor: pointer;
  /* border:2px solid red; */

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  z-index: 3;
}

.btn-dark{
  color:var(--dark-btn-color);
  /* color: transparent; */
  /* background-color: #edede9; */
  /* mix-blend-mode:screen; */
}

.disabled{
  color:  rgb(88, 88, 88);;
  cursor: default;

}

.btn:hover{
  color: rgb(88, 88, 88);;
}

.stable:hover{
  color: white;
}

/*///////////////////////////////////////////////*/
/*/////////////////ScrollBar/////////////////////*/
/*///////////////////////////////////////////////*/
 
/* width */
 ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #e24545; */
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
} 

/*///////////////////////////////////////////////*/
/*///////////////Responsive Design///////////////*/
/*///////////////////////////////////////////////*/
@media only screen and (max-width: 768px ){
  .btn{
      font-size: 0.7rem;
  }

  .overlay-container>h1{
    font-size: 1rem;
  }

  .r{
    width: 30px;
  }

  .r-dot{
    width: 15px;
    height: 15px;
  }
}

@media only screen and (max-width: 500px ){

  .template-container>h1{
    font-size: 1rem;
  }

  .cr-doc-btn-box{
    width: 80vw;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  
    row-gap: 2vh;
  
    /* border: 1px solid red; */
  }
  
  .cr-doc-btn{
    width: 100%;
    height: 5vh;
  
    padding: 0.5rem 1rem;
  
    /* border: 1px solid blue; */
  
    background-color: #adadad;
    cursor: pointer;
  
    display: flex;
    justify-content: flex-start;
    align-items: center;
  
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 0.8rem;
    color: var(--templateBackground);
  
    transition: 0.3s ease-in-out;
  }

  .moa-box{
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
  
    border: 1px solid red;
  }
  
  .moa-box>img{
    width: 80vw;
    height: auto;
  
    object-fit: contain;
  }
}