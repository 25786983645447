.contact-container{
    /* position: absolute; */
    position: fixed;
    top: 0;
    left:50%;
    transform: translate(-50%,0);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 65%;
    /* min-height: 30vh; */
    height: 100vh;

    /* border: 2px solid red; */

    overflow-y: auto;
    overflow-x: hidden;

    z-index: 1;
}

.contact-content{
    position: absolute;
    top: 0;

    padding: 20vh 2vw;
    
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items:center;

    width: 100%;
    height: 100vh;

    font-size: 1rem;
    color: white;
    text-align: center;

    /* border: 2px solid blue; */
}

.contact-content>p{
    margin-bottom: 1rem;
    font-size: 1rem;
    font-family: 'Albert Sans', sans-serif;
}

.info-grid{
    display: grid;
    grid-template-columns: 32% 32% 32%;
    column-gap: 1rem;

    margin: 10vh 0 5vh 0;

    width: 100%;
    height: auto;

    font-size: 1rem;

    /* border: 2px solid red; */
}

.info-item{
    font-family: 'Albert Sans', sans-serif;
}

.info-item>a{
    color: white;
    text-decoration: none;
}

.info-item>p, .info-item>h3, .info-item>a, .info-item>a>p{
    -webkit-user-select: text; /* Safari */
    -ms-user-select: text; /* IE 10 and IE 11 */
    user-select: text; /* Standard syntax */
    
    font-family: 'Albert Sans', sans-serif;
    font-size: 1rem;
    word-wrap: break-word;
    /* border: 2px solid red; */
}

.info-item>a>p{
    margin-bottom: 0;
}

.info-item>p::selection, .info-item>h3::selection, .info-item>a::selection,  .info-item>a>p::selection{
    color:white;
    background: rgb(141, 141, 141);
}

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 768px ){
    .contact-container{
        height: 100vh;
        top: 0;
        left: 100%;
        /* left: 60%; */
        transform: translate(-100%,0);
        width: 68vw;   
        padding-right: 5vw;  
        /* border: 2px solid red; */
        
        /* justify-content: flex-start;
        align-items: flex-start; */
    }

    .contact-content{
        height: 100%;
        padding: 10vh 10px;
    }

    .contact-content>p{
        font-size: 0.5rem;
    }

    .info-grid{
        font-size: 0.5rem;
        grid-template-columns: auto;
        margin: 2vh 0;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .info-item{
        padding-left: 0;
        margin-top: 10px;
        grid-column: span 2;
    }

    .info-item>p, .info-item>h3, .info-item>a,  .info-item>a>p{
        font-size: 0.5rem;
    }

    .info-item:nth-child(3){
        position: block;
        /* border: 2px solid blue; */
        margin-top: 15px;
        grid-column: span 2;
        left: 50%;
    }
}