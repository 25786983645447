.media-content{
    position: relative;
    /* margin-top: clamp(80px, 8vh, 8vh); */

    padding: 3vh 3vh;
    /* margin-top: 30vh; */
    margin-bottom: clamp(200px, 20vh, 20vh);

    display: flex;
    justify-content: center;
    align-items: center ;
    width: 75vw;
    min-height: 60vh;
    
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.9px);
    -webkit-backdrop-filter: blur(5.9px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    /* background-color: #f5ebe0; */
    border-radius: 50px;
    
    /* border:2px solid green; */
}

.media-wrapper{
    display: grid;

    grid-template-columns: auto auto; 
    padding: 2rem 1rem;
    gap:2rem;
}

.media-title{
    width: 100%;
    min-height: 20vh;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    padding:0 5vw ;

    font-size: 2rem;
    text-align: center;
    color: white;
    margin-top:clamp(80px, 8vh, 8vh);
}
.media-title>h1{
    width:fit-content;
    justify-self: center;
    text-decoration: none; 
    position: relative;
    /* background-color: ; */
}
.media-title>h1::after{
    content : "";
    position : absolute;
    width : 100%;
    height : 3px;
    background-color:  white;
    bottom: 0;
    left: 0;
    transform : scaleX(0.5) ;
    transform-origin: bottom center;
    transition : transform 0.4s linear;
}

.media-title>h1:hover::after{
    transform: scaleX(0.25) ;
}

.media-subscript{
    margin: 30px 0;
    width:50%; 

    font-family: 'Quattrocento', serif;
    font-size:1rem;
    color: rgb(83, 83, 83);
}

.link-tag:hover{
    transform: scale(1.02);
    transition: 0.3s ease-in-out;
}

.link-tag{
    text-decoration: none;
    transition: 0.3s ease-in-out;
    
}

.media-item{
    display: flex;
    width: 100%;
    height: 120px;
    
    justify-self: center;
    background-color: rgba(255, 254, 254, 0.603);
    /* border: 2px solid blue; */

    border-radius: 30px;

    justify-content: center;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.media-item:hover{
    background-color: white;
    transition: 0.3s ease-in-out;
}

.media-item>h3{
    justify-self: flex-start;
    align-self: center;

    padding: 0 0.5rem;

    font-size: 0.8rem;
    width: 80%;
    color: #292929;
    text-align: center;
    word-wrap: normal;
}

.media-img{
    justify-self: flex-start;
    width: 40%;
    /* height: 100%; */
    /* height: auto; */
    /* background-color: black; */
}

.media-img>img{
    width: 100%;
    /* height: auto; */
    height: 120px;
    object-fit:cover;
    border-radius: 30px;
}

@media only screen and (max-width: 900px ){
    .media-wrapper{
        grid-template-columns: auto;
    }
}

@media only screen and (max-width: 786px ){
    .media-content{
        width: 90vw;
    }

    .media-wrapper{
        padding: 0.8rem 0.5rem;
    }
}